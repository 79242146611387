<template>
    <Dialog :visible modal :dismissable-mask="true" :unstyled="true" :pt @update:visible="updateVisible">
        <template #closeicon>
            <span class="flex icon-close" />
        </template>

        <template #header>
            <div class="title" :class="{ 'title-form': form }">
                <slot name="header" />
            </div>
        </template>

        <slot />

        <template v-if="$slots.footer" #footer>
            <slot name="footer" />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog';

/** Interfaces **/
interface Props {
    visible: boolean;
    form?: boolean;
    classContent?: string;
}

/** Props **/
const { visible = false, form = false, classContent = '' } = defineProps<Props>();

/** Emits **/
const emit = defineEmits(['update:visible']);

/** Data **/
const pt = readonly({
    root: 'modal',
    header: 'modal__header',
    title: 'modal__title',
    headerActions: 'modal__header-actions',
    pcCloseButton: {
        root: 'modal__close-button',
        label: 'modal__close-button__label',
    },
    content: `modal__content ${toValue(classContent)}`,
    footer: 'modal__footer',
    mask: () => toValue(maskClass).join(' '),
});

const maskClass = ref<string[]>(['modal__mask', 'modal-enter-active']);

/** Methods **/
const updateVisible = (value: boolean): void => {
    maskClass.value.push('modal-leave-active');

    setTimeout(() => {
        emit('update:visible', value);
        maskClass.value = toValue(maskClass).filter((c: string) => c !== 'modal-leave-active');
    }, 100);
};
</script>

<style lang="scss">
@use "assets/styles/variables" as *;
@use "assets/styles/keyframes" as *;

.modal {
    padding: 24px;
    border: 1px solid $color-primary-light;
    border-radius: $border-radius;
    background-color: $color-white;
    max-width: 90vw;
    max-height: 90vh;

    &-enter-active {
        animation: overlay-mask-enter-animation 0.25s forwards;
    }

    &-leave-active {
        animation: overlay-mask-leave-animation 0.25s forwards;
    }

    &__mask {
        background-color: $color-overlay;
    }

    &__header {
        display: flex;

        &-actions {
            margin-left: auto;
        }

        .title {
            color: $color-black-original;
            font-size: $size-24;
            font-weight: $weight-500;
            line-height: $line-height-130;
            margin-right: 12px;
        }

        .title-form {
            font-size: $size-20;
            color: $color-black;
        }
    }

    &__content {
        margin-top: 24px;
        overflow-y: auto;
    }

    &__close-button {
        &__label {
            display: none;
        }
    }
}
</style>
